import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse, HttpParams} from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import {Page, Udi} from '../../shared/models';
import {IocPriorityRange} from '../../shared/models/ioc/ioc_priorityrange.model';
import {IocFlaggedMac} from '../../shared/models/ioc/ioc_flaggedmac.model';
import {IocFlaggedUser} from '../../shared/models/ioc';

/*

Le fragmenter en plusieurs services qui tiendront dans un dossier.

 */

@Injectable({
  providedIn: 'root'
})
export class IocService {
  private httpOptions = {
    headers: new HttpHeaders().set('Content-Type', 'application/json'),
  };
  private url = `${environment.server}/api/v1/compromised`;
  compromised_element: IocFlaggedMac;

  constructor(
    private http: HttpClient,
    private spinner: NgxSpinnerService
  ) { }

  // button liveSearch
  findMac(macAddress: string): Observable<any> {
    this.spinner.show();
    return this.http.post(`${environment.server}/api/v1/tools/find_mac_live`, { mac: macAddress }, this.httpOptions)
      .pipe(
        tap(() => this.spinner.hide()),
        catchError((err: HttpErrorResponse) => this.handleError(err))
      );
  }

  private handleError(err): Observable<any> {
    this.spinner.hide();
    return throwError(err);
  }

  /**************************************** LIST ****************************************/
  getCompromised(page: Page, search = '', order = 0 , orderField= ''):
    Observable<{ compromised: IocFlaggedMac[], totalElements: number}> {

    let params = new HttpParams()
      .set('filter', search.trim())
      .set('pageSize', String(page.size))
      .set('pageNumber', String(page.pageNumber))
      .set('isMinified', 'true');
    if ((order === 1 || order === -1) && orderField && orderField.trim() !== '') {
      params = order === -1
        ? params.append('orderBy', `-${orderField}`)
        : params.append('orderBy', orderField);
    }
    return this.http.get(this.url,
      { ...this.httpOptions, params}) as Observable<{ compromised: IocFlaggedMac[], totalElements: number}>;
  }

  /**************************************** DETAILS ****************************************/
  getDetailCompromised(id: number): Observable<IocFlaggedMac> {
    return this.http.get(`${this.url}/${id}`) as Observable<IocFlaggedMac>;
  }


  /**************************************** COMMENTS ****************************************/
  addComment(pk: number, option: string, content: string) {
    const params = new HttpParams()
      .append(option, 'True')
      .append('content', content);
    return this.http.post(`${this.url}/${pk}/comment`, params);
  }

  deleteComment(id: number, option: string) {
    const params = new HttpParams()
      .set(option, 'true');
    return this.http.delete(`${this.url}/${id}/comment`,
      { ...this.httpOptions, params});
  }

  /**************************************** CLEANING HOST ************************************/
  cleanMachine(id: number) {
    const params = new HttpParams();
    return this.http.delete(`${this.url}/${id}/clean`,
      { ...this.httpOptions, params});
  }

  /**************************************** CLAIMING HOST ************************************/
  claimMachine(id: number) {
    const params = new HttpParams();
    return this.http.post(`${this.url}/${id}/claim`,
      { ...this.httpOptions, params});
  }

  unclaimMachine(id: number) {
    const params = new HttpParams();
    return this.http.delete(`${this.url}/${id}/claim`,
      { ...this.httpOptions, params});
  }

  /****************************************** EMAIL **********************************************/
  sendMail(id: number, subject: string, content: string, emails: string[]) {

    let params = new HttpParams()
      // sinon le contenu de l'email est divisé en dict dans la requête à chaque ;
      // replaceAll non utilisable car pas supporté par tous les browsers
      .set('message', content.split(';').join('#POINTVIRGULE#'))
      .set('default_subject', subject);

    for (let i = 0; i < emails.length; i++) {
      params = params.append(i.toString(), emails[i]);
    }

    return this.http.post(`${this.url}/${id}/email`, params);
  }

  getMailRecipient(mac_id: number): Observable<IocFlaggedUser[]> {
    return this.http.get(`${this.url}/${mac_id}/email/recipients`) as Observable<IocFlaggedUser[]>;
  }

  getMailTemplate(email_type?: string): Observable<Object> {
    if (!email_type) {
      email_type = 'default_mail';
    }
    const params = new HttpParams()
      .set(email_type, 'true');
    return this.http.get(`${this.url}/email/template`, { ...this.httpOptions, params}) as Observable<Object>;
  }

  postMailTemplate(email_type: string, content: string) {
    const params = new HttpParams()
      // sinon le contenu du mail va être divisé en partie de dictionnaire à chaque ;
    // replaceAll() n'est pas utilisable car non supporté par tout les browers (... IE)
      .set(email_type, content.split(';').join('#POINTVIRGULE#'));
    return this.http.post(`${this.url}/email/template`, params);
  }

  // the next two functions are used to pass along the details of the compromised host
  // when the user wants to send an email
  // in order to prevent spamming the user -> 'forcing' the page won't work that way
  saveElement(element: IocFlaggedMac) {
    this.compromised_element = element;
  }
  loadElement() {
    return this.compromised_element;
  }

  /*************************************** STATS ********************************************************/
  getStats(duration: number = 7, chartName: string): Observable<any> {
    const params = new HttpParams()
      .set('duration', String(duration))
      .set('type', chartName);

    return this.http.get<any>(`${environment.server}/api/v1/stats/compromised`,  { ...this.httpOptions, params });
  }

  /* ADMIN */
  /****************************** WHITELIST ************************************************************/
  addWhitelist(pk: number, option: string) {
    const params = new HttpParams()
      .set(option, 'True');
    return this.http.post(`${this.url}/${pk}/whitelist`, params);
  }

  deleteWhitelist(id: number, option: string) {
    const params = new HttpParams()
      .set(option, 'true');
    return this.http.delete(`${this.url}/${id}/whitelist`,
      { ...this.httpOptions, params});
  }

  getWhitelist(option: string) {
    const params = new HttpParams()
      .set(option, 'true');
    return this.http.get(`${this.url}/whitelist`,
      { ...this.httpOptions, params});
  }

  /************************************** PARAMETERS *********************************************/
  getSectionContent(section: string): Observable<Object> {
    const params = new HttpParams()
      .set('section', section);
    return this.http.get(`${this.url}/section`,
      { ...this.httpOptions, params}) as Observable<Object>;
  }

  changeParameter(section: string, key: string, value: string) {
    const params = new HttpParams()
      .set('section', section)
      .set(key, value);

    console.log(params);
    return this.http.post(`${this.url}/settings`, params);
  }

  /************************************ ASSIGN UDI ***********************************************************/
  getUdiList(): Observable<Udi[]> {
    return this.http.get(`${environment.server}/api/v1/udilist`) as Observable<Udi[]>;
  }

  changeUdi(id: number, udi_id: number) {
    const params = new HttpParams()
      .set('udi', udi_id.toString());

    return this.http.post(`${this.url}/assign/${id}`, params);
  }

  /*********************************** PRIORITY RANGES ****************************************************/
  getPriorityRanges(): Observable<IocPriorityRange[]> {
    return this.http.get(`${this.url}/prioranges`) as Observable<IocPriorityRange[]>;
  }

  addPriorityRange(priorange: IocPriorityRange ) {
    let params = new HttpParams()
      .set('range', priorange.range);
    if (priorange.emailThreshold) {
      params = params.append('email_thres', priorange.emailThreshold.toString());
      params = params.append('email', priorange.email);
    }

    if (priorange.textThreshold) {
      params.set('text_thres', priorange.textThreshold.toString());
      params.set('phone', priorange.phone);
    }

    return this.http.post(`${this.url}/prioranges`, params);

  }

  deletePriorityRange(range: string) {
    const params = new HttpParams()
      .set('range', range);

    return this.http.delete(`${this.url}/prioranges`,{ ...this.httpOptions, params});
  }

}
