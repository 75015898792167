import { AbstractControl, ValidationErrors } from '@angular/forms';

function trimField(field: any) {
  const value =  field || '';
  return typeof value !==  'string' ? value.toString().trim() : value.trim();
}

export class CustomValidator {
  static notBlank(control: AbstractControl ) {
    return trimField(control.value).length !== 0 ? null : {'notBlank': true};
  }

  /** Trim before comparing the length **/
  static minLength(minLength: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = trimField(control.value);
      const length: number = value ? value.length : 0;
      return length < minLength ?  {'minlength': {'requiredLength': minLength, 'actualLength': length}} :  null;
    };
  }
}

